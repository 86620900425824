@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Lato-Black";
    src: url("./assets/fonts/Lato-Black.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato-BlackItalic";
    src: url("./assets/fonts/Lato-BlackItalic.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato-Bold";
    src: url("./assets/fonts/Lato-Bold.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato-BoldItalic";
    src: url("./assets/fonts/Lato-BoldItalic.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato-Italic";
    src: url("./assets/fonts/Lato-Italic.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato-Light";
    src: url("./assets/fonts/Lato-Light.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato-LightItalic";
    src: url("./assets/fonts/Lato-LightItalic.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato-Regular";
    src: url("./assets/fonts/Lato-Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SourceCodePro";
    src: url("./assets/fonts/SourceCodePro-Variable.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SourceCodeProItalic";
    src: url("./assets/fonts/SourceCodeProItalic-Variable.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}